import { useState } from "react";
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button, Container, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import agent from "../app/api/agent";
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from "react-redux";
import { setLoading } from "../app/stores/loadingSlice";

interface LaporanProps{
    category: string
}
export default function LaporanPage({category}: LaporanProps){
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const dispatch = useDispatch()
    let timeZoneOffsetInMinutes: number

    const handleDownload = async () => {
        dispatch(setLoading(true))
        if (startDate != null && endDate != null){
            timeZoneOffsetInMinutes = startDate.getTimezoneOffset();
            startDate.setHours(0,0,0,0)
            endDate.setHours(23,59,59,0)
                switch(category) {
                    case "Neraca":
                        getNeraca(new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
                        break;
                    case "Laporan Laba Rugi":
                        getLabaRugi(new Date(startDate.getTime() + timeZoneOffsetInMinutes * 60000), new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
                        break;
                    case "Arus Kas":
                        getCashFlow(new Date(startDate.getTime() + timeZoneOffsetInMinutes * 60000), new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
                        break;
                    default:
                        getJurnal(new Date(startDate.getTime() + timeZoneOffsetInMinutes * 60000), new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
                }
        }
        dispatch(setLoading(false))
    }

    const getJurnal = async (startDate: Date, endDate: Date) => {
        try{
            const transactions = (await agent.Transactions.get(startDate, endDate)).data
            const workBook = new excelJS.Workbook()
            const sheet = workBook.addWorksheet("jurnal umum")
            sheet.columns = [{width:30},{width:30},{width:20},{width:20},{width:30},{width:20},{width:20},{width:20}]
            sheet.getColumn(7).numFmt = '#,##0';
            sheet.getColumn(8).numFmt = '#,##0';
            sheet.mergeCells('A1', 'H2');
            sheet.mergeCells('A3', 'H3');
            sheet.getCell('A1').value = "Jurnal Umum"
            sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getCell('A3').value = `Dari ${new Date(startDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} s/d ${new Date(endDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
            // perlu dikurang timezone offset agar tanggalnya bener
            sheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getRow(5).values = ['Tanggal','Tipe Transaksi Jurnal','Nomor Transaksi','Nama Akun','Keterangan','Klien','Debit','Kredit']
            for (let i=0; i < transactions.length; i++){
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = new Intl.DateTimeFormat('id-ID', options).format(new Date(transactions[i].createdAt));
                sheet.getRow(6+i).values = [formattedDate,transactions[i].category,transactions[i].noTransaksi,transactions[i].NamaPerkiraan.name,transactions[i].keterangan,
                ((transactions[i].Klien == null) ? "" : transactions[i].Klien.nama),
                ((transactions[i].debitKredit === "debit") ? transactions[i].value : "0"),
                ((transactions[i].debitKredit === "kredit") ? transactions[i].value : "0")
            ]
            }
            workBook.xlsx.writeBuffer().then(function(buffer) {                        
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, "jurnalUmum.xlsx");
            });
        } catch(error){
            console.log(error)
        }
    }

    const getNeraca = async (endDate: Date) => {
        try{
            const datas = (await agent.Laporans.neraca(new Date(new Date().getFullYear(),0,1,0,0,0), endDate)).data
            const workBook = new excelJS.Workbook()
            const sheet = workBook.addWorksheet("neraca")
            sheet.mergeCells('A1', 'I2');
            sheet.mergeCells('A3', 'I3');
            sheet.getCell('A1').value = "Neraca"
            sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getCell('A3').value = `per tanggal ${new Date(endDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
            sheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getRow(5).values = ['Deskripsi','','','Nilai','','Deskripsi','','','Nilai']
            sheet.getRow(6).values = ['Aset','','','','','Liabilitas']
            const hu = datas.filter(d => d.category === "hutang usaha")
            const purehu = hu.filter(d => d.name === "hutang usaha")
            const nonpurehu = hu.filter(d => d.name !== "hutang usaha")
            const totHu = purehu.length > 0 ? purehu[0].value : 0
            sheet.getRow(7).values = ['','Kas','','','','','Hutang Usaha','',totHu]
            sheet.columns = [{width:10},{width:30},{width:20},{width:20},{width:10},{width:30},{width:30},{width:20},{width:20}]
            sheet.getColumn(4).numFmt = '#,##0';
            sheet.getColumn(9).numFmt = '#,##0';

            const kas = datas.filter(d => d.category === "kas")
            let totKas = 0
            for (let i=0; i < kas.length; i++){
                console.log(kas[i].name)
                const row = sheet.getRow(8+i)
                row.getCell(3).value = kas[i].name
                row.getCell(4).value = kas[i].value
                totKas += kas[i].value
            }
            let leftIndex = 8+kas.length
            sheet.getRow(leftIndex).values = ['','Jumlah Kas','',totKas]

            leftIndex += 2
            const pu = datas.filter(d => d.category === "piutang usaha")
            const purepu = pu.filter(d => d.name === "piutang usaha")
            const nonpurepu = pu.filter(d => d.name !== "piutang usaha")
            const totPu = purepu.length > 0 ? purepu[0].value : 0
            sheet.getRow(leftIndex).values = ['','Piutang Usaha','',totPu]

            leftIndex += 2
            sheet.getRow(leftIndex).values = ['','Piutang Lainnya']
            leftIndex += 1
            const pl = datas.filter(d => d.category === "piutang lainnya")
            let totPl = 0
            for (let i=0; i < nonpurepu.length; i++){
                const row = sheet.getRow(leftIndex+i)
                row.getCell(3).value = nonpurepu[i].name
                row.getCell(4).value = nonpurepu[i].value
                totPl += nonpurepu[i].value
            }
            leftIndex += nonpurepu.length                
            for (let i=0; i < pl.length; i++){
                const row = sheet.getRow(leftIndex+i)
                row.getCell(3).value = pl[i].name
                row.getCell(4).value = pl[i].value
                totPl += pl[i].value
            }
            leftIndex += pl.length
            sheet.getRow(leftIndex).values = ['','Jumlah Piutang Lainnya','',totPl]

            leftIndex += 2
            const per = datas.filter(d => d.category === "persediaan")
            const totPer = per.length > 0 ? per[0].value : 0
            sheet.getRow(leftIndex).values = ['','Persediaan','',totPer]

            leftIndex += 2
            sheet.getRow(leftIndex).values = ['','Aset Lancar Lainnya']
            const asetLancars = datas.filter(d => d.category === "aset lancar")
            let totAsetLancar = 0
            for (let i=0; i < asetLancars.length; i++){
                const row = sheet.getRow(leftIndex+i+1)
                row.getCell(3).value = asetLancars[i].name
                row.getCell(4).value = asetLancars[i].value
                totAsetLancar += asetLancars[i].value
            }
            leftIndex = leftIndex+asetLancars.length+1
            sheet.getRow(leftIndex).values = ['','Jumlah Aset Lancar Lainnya','',totAsetLancar]

            leftIndex += 2
            sheet.getRow(leftIndex).values = ['','Aset Tidak Lancar Lainnya']
            const asetTLancars = datas.filter(d => d.category === "aset tidak lancar")
            let totAsetTLancar = 0
            for (let i=0; i < asetTLancars.length; i++){
                const row = sheet.getRow(leftIndex+i+1)
                row.getCell(3).value = asetTLancars[i].name
                row.getCell(4).value = asetTLancars[i].value
                totAsetTLancar += asetTLancars[i].value
            }
            leftIndex = leftIndex+asetTLancars.length+1
            sheet.getRow(leftIndex).values = ['','Jumlah Aset Tidak Lancar Lainnya','',totAsetTLancar]

            // right neraca:
            let rightIndex = 9
            sheet.getRow(rightIndex).getCell(7).value = 'Hutang Lainnya'
            rightIndex += 1
            const hl = datas.filter(d => d.category === "hutang lainnya")
            let totHl = 0
            for (let i=0; i < nonpurehu.length; i++){
                const row = sheet.getRow(rightIndex+i)
                row.getCell(8).value = nonpurehu[i].name
                row.getCell(9).value = nonpurehu[i].value
                totHl += nonpurehu[i].value
            }
            rightIndex += nonpurehu.length                
            for (let i=0; i < hl.length; i++){
                const row = sheet.getRow(rightIndex+i)
                row.getCell(8).value = hl[i].name
                row.getCell(9).value = hl[i].value
                totHl += hl[i].value
            }
            rightIndex += hl.length
            sheet.getRow(rightIndex).getCell(7).value = "Jumlah Hutang Lainnya"
            sheet.getRow(rightIndex).getCell(9).value = totHl

            rightIndex += 2
            sheet.getRow(rightIndex).getCell(6).value = "Jumlah Liabilitas"
            let totLia = totHl + totHu
            sheet.getRow(rightIndex).getCell(9).value = totLia

            rightIndex += 2
            sheet.getRow(rightIndex).getCell(6).value = "Ekuitas"
            rightIndex += 1
            let ekuitas = datas.filter(d => d.category === "ekuitas")
            let totEkuitas = 0
            for (let i=0; i < ekuitas.length; i++){
                const row = sheet.getRow(rightIndex+i)
                row.getCell(7).value = ekuitas[i].name
                row.getCell(9).value = ekuitas[i].value
                totEkuitas += ekuitas[i].value
            }                        
            rightIndex += ekuitas.length
            sheet.getRow(rightIndex).getCell(6).value = "Jumlah Ekuitas"
            sheet.getRow(rightIndex).getCell(9).value = totEkuitas

            const lastRow = Math.max(leftIndex, rightIndex)
            sheet.getRow(lastRow+2).values = ['Jumlah Aset','','',totKas+totPu+totPl+totPer+totAsetLancar+totAsetTLancar,'','Jumlah Liabilitas dan Ekuitas','','',totEkuitas+totLia]

            workBook.xlsx.writeBuffer().then(function(buffer) {                        
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, "Neraca.xlsx");
            });
        } catch(error){
            console.log(error)
        }
    }

    const getLabaRugi = async (startDate: Date, endDate:Date) => {
        try{
            const datas = (await agent.Laporans.labaRugi(startDate, endDate)).data
            const workBook = new excelJS.Workbook()
            const sheet = workBook.addWorksheet("labarugi")
            sheet.columns = [{width:30},{width:20},{width:10},{width:20}]
            sheet.getColumn(4).numFmt = '#,##0';
            sheet.mergeCells('A1', 'D2');
            sheet.mergeCells('A3', 'D3');
            sheet.getCell('A1').value = "Laba/Rugi"
            sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getCell('A3').value = `Dari ${new Date(startDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} s/d ${new Date(endDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
            sheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getRow(5).values = ['Deskripsi','','','Jumlah']
            sheet.getRow(7).values = ['PENDAPATAN']
            const pend = datas.filter(d => d.NamaPerkiraan.category === "pendapatan operasional")
            let totPend = 0
            let indeks = 8
            for (let i=0; i < pend.length; i++){
                const row = sheet.getRow(indeks+i)
                row.getCell(2).value = pend[i].NamaPerkiraan.name
                row.getCell(4).value = pend[i].sumValue
                totPend += pend[i].sumValue
            }
            indeks += pend.length
            sheet.getRow(indeks).values = ['Jumlah Pendapatan','','',totPend]

            const bpp = datas.filter(d => d.NamaPerkiraan.category === "beban pokok penjualan")
            indeks += 2
            const totbpp = bpp.length > 0 ? bpp[0].sumValue : 0
            sheet.getRow(indeks).values = ['BEBAN POKOK PENJUALAN','','',totbpp]
            indeks += 2
            const labaKotor = totPend-totbpp
            sheet.getRow(indeks).values = ['LABA KOTOR','','',labaKotor]

            indeks += 2
            sheet.getRow(indeks).values = ['Beban Operasional']
            indeks += 1
            const bop = datas.filter(d => d.NamaPerkiraan.category === "beban operasional")
            let totbop = 0
            for (let i=0; i < bop.length; i++){
                const row = sheet.getRow(indeks+i)
                row.getCell(2).value = bop[i].NamaPerkiraan.name
                row.getCell(4).value = bop[i].sumValue
                totbop += bop[i].sumValue
            }
            indeks += bop.length
            sheet.getRow(indeks).values = ['Jumlah Beban Operasional','','',totbop]
            indeks += 2
            const pendOps = labaKotor - totbop
            sheet.getRow(indeks).values = ['PENDAPATAN OPERASIONAL','','',pendOps]

            indeks += 2
            sheet.getRow(indeks).values = ['Beban Non Operasional']
            indeks += 1
            const bnops = datas.filter(d => d.NamaPerkiraan.category === "beban nonoperasional")
            let totbnops = 0
            for (let i=0; i < bnops.length; i++){
                const row = sheet.getRow(indeks+i)
                row.getCell(2).value = bnops[i].NamaPerkiraan.name
                row.getCell(4).value = bnops[i].sumValue
                totbnops += bnops[i].sumValue
            }
            indeks += bnops.length
            sheet.getRow(indeks).values = ['Jumlah Beban Non Operasional','','',totbnops]
            indeks += 1
            sheet.getRow(indeks).values = ['Pendapatan Non Operasional']
            indeks += 1
            const pnops = datas.filter(d => d.NamaPerkiraan.category === "pendapatan nonoperasional")
            let totpnops = 0
            for (let i=0; i < pnops.length; i++){
                const row = sheet.getRow(indeks+i)
                row.getCell(2).value = pnops[i].NamaPerkiraan.name
                row.getCell(4).value = pnops[i].sumValue
                totpnops += pnops[i].sumValue
            }
            indeks += pnops.length
            sheet.getRow(indeks).values = ['Jumlah Pendapatan Non Operasional','','',totpnops]
            indeks += 1
            const totnonops = totpnops - totbnops
            sheet.getRow(indeks).values = ['Jumlah Pendapatan dan Beban Non Operasional','','',totnonops]

            const lababersih = totnonops + pendOps
            indeks += 2
            sheet.getRow(indeks).values = ['LABA BERSIH (Sebelum Pajak)','','',lababersih]
            indeks += 1
            sheet.getRow(indeks).values = ['Beban Pajak']
            indeks += 1
            const pajs = datas.filter(d => d.NamaPerkiraan.category === "beban pajak")
            let totpajs = 0
            for (let i=0; i < pajs.length; i++){
                const row = sheet.getRow(indeks+i)
                row.getCell(2).value = pajs[i].NamaPerkiraan.name
                row.getCell(4).value = pajs[i].sumValue
                totpajs += pajs[i].sumValue
            }
            indeks += pajs.length
            sheet.getRow(indeks).values = ['Jumlah Beban Pajak','','',totpajs]
            indeks += 1
            sheet.getRow(indeks).values = ['LABA BERSIH (Setelah Pajak)','','',lababersih-totpajs]

            workBook.xlsx.writeBuffer().then(function(buffer) {                        
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, "labarugi.xlsx");
            });
        } catch(error){
            console.log(error)
        }
    }

    const getCashFlow = async (startDate: Date, endDate:Date) => {
        try{
            const datas = (await agent.Laporans.cashflow(startDate, endDate)).data
            const workBook = new excelJS.Workbook()
            const sheet = workBook.addWorksheet("cashflow")
            sheet.columns = [{width:30},{width:20},{width:10},{width:20}]
            sheet.getColumn(4).numFmt = '#,##0';
            sheet.mergeCells('A1', 'D2');
            sheet.mergeCells('A3', 'D3');
            sheet.getCell('A1').value = "Arus Kas"
            sheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getCell('A3').value = `Dari ${new Date(startDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })} s/d ${new Date(endDate.getTime() - timeZoneOffsetInMinutes * 60000).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}`
            sheet.getCell('A3').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            sheet.getRow(5).values = ['Deskripsi','','','Jumlah']
            sheet.getRow(7).values = ['AKTIVITAS OPERASI']
            const pendanaanList = ["ekuitas"]
            const cashflowKases = datas.kases
            const operasis = cashflowKases.filter(d => !pendanaanList.includes(d.cashflowTransaction.NamaPerkiraan.category))
            const pendanaans = cashflowKases.filter(d => pendanaanList.includes(d.cashflowTransaction.NamaPerkiraan.category))
            let totOps = 0
            let totPends = 0
            let indeks = 8
            for (let i=0; i < operasis.length; i++){
                const val = operasis[i].totalValue * ((operasis[i].debitKredit === "debit") ? 1 : -1)
                sheet.getRow(indeks+i).values = ['',operasis[i].cashflowTransaction.NamaPerkiraan.name,'',val]
                totOps += val
            }
            indeks += operasis.length
            sheet.getRow(indeks).values = ['Jumlah Aktivitas Operasi','','',totOps]
            indeks += 2
            sheet.getRow(indeks).values = ['AKTIVITAS INVESTASI']
            indeks += 1
            sheet.getRow(indeks).values = ['Jumlah Aktivitas Investasi','','',0]
            indeks+=2
            sheet.getRow(indeks).values = ['AKTIVITAS PENDANAAN']
            indeks+=1
            for (let i=0; i < pendanaans.length; i++){
                const val = pendanaans[i].totalValue * ((pendanaans[i].debitKredit === "debit") ? 1 : -1)
                sheet.getRow(indeks+i).values = ['',pendanaans[i].cashflowTransaction.NamaPerkiraan.name,'',val]
                totPends += val
            }
            indeks += pendanaans.length
            sheet.getRow(indeks).values = ['Jumlah Aktivitas Pendanaan','','',totPends]
            indeks += 2
            sheet.getRow(indeks).values = ['KENAIKAN/PENURUNAN KAS','','',totPends+totOps]
            sheet.getRow(indeks+1).values = ['KAS DI AWAL PERIOD','','',datas.saldoAkhir-(totPends+totOps)]
            sheet.getRow(indeks+2).values = ['KAS DI AKHIR PERIOD','','',datas.saldoAkhir]

            workBook.xlsx.writeBuffer().then(function(buffer) {                        
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, "Cashflow.xlsx");
            });
        }catch(error){
            console.log(error)
        }
    }

    return (
        <Container className="mt-5">
        <h1 className="mb-3">Unduh {category}:</h1>
        <Row hidden={category==="Neraca"}>
            <label>Pilih tanggal awal:</label>
            <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
        </Row>
        <Row>
            <label>Pilih tanggal akhir:</label>
            <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
        </Row>
        <Button variant="primary" onClick={handleDownload} className="mt-3">
            Unduh
        </Button>
        </Container>
    )
}