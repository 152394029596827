import { FormEvent, useEffect, useState } from "react";
import agent from "../app/api/agent";
import { Namper } from "../app/models/namper";
import { Button, Container, Form } from "react-bootstrap";
import { TransactionBody } from "../app/models/transaction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoading } from "../app/stores/loadingSlice";
import { useDispatch } from "react-redux";
import { handleNumberTextFieldChange } from "../app/util/numberTextFieldUtil";

export default function TanamModal(){
    const [ekuitas, setEkuitas] = useState<Namper[]>([])
    const [kas, setKas] = useState<Namper[]>([])
    const [equId,setEquId] = useState(0)
    const [kasId,setKasId] = useState(0)
    const [ket,setKet] = useState("")
    const [jumlah,setJumlah] = useState(0)
    const [jumlahString,setJumlahString] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
        try {
            const ekuiti = (await agent.Nampers.list('ekuitas')).data
            const kass = (await agent.Nampers.list('kas')).data
            setEkuitas(ekuiti)
            setKas(kass)
        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
    }, [])

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        dispatch(setLoading(true))
        try {
        const transaction = await agent.Transactions.create(new TransactionBody("Tanam Modal","",ket,jumlah,"kredit",false,0,equId,Date(),null))
        await agent.Transactions.create(new TransactionBody("Tanam Modal",transaction.data.noTransaksi,ket,jumlah,"debit",false,0,kasId,transaction.data.createdAt,transaction.data.id))
        toast.success("transaksi berhasil dibuat")
        navigate('/home')
        } catch(error){
            console.log(error)
        }
        dispatch(setLoading(false))
    }

    return (
        <Container className="mt-5">
            <h1 className="mb-3">Tanam Modal:</h1>
            <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formLia" className="mb-3">
                <Form.Label>Pilih Ekuitas:</Form.Label>
                <Form.Select
                  value={equId}
                  onChange={(e) => setEquId(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {ekuitas.map((l, index) => (
                        <option key={index} value={l.id}>{l.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="formKas" className="mb-3">
                <Form.Label>Pilih Kas:</Form.Label>
                <Form.Select
                  value={kasId}
                  onChange={(e) => setKasId(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {kas.map((k, index) => (
                        <option key={index} value={k.id}>{k.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="formKet" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formJum">
                <Form.Label>Jumlah:</Form.Label>
                <Form.Control
                type="text"
                placeholder="input jumlah"
                value={jumlahString}
                // onChange={(e) => 
                //     setJumlah(parseInt(e.target.value))
                //     setJumlahString(formatNumberWithCommas(e.target.value))
                // }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setJumlah, setJumlahString)}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
            </Form>
        </Container>
    )
}