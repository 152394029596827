import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NamperAbs } from '../models/namper'

export interface NamperAbsState {
  value: NamperAbs
}

const initialState: NamperAbsState = {
  value: {
    persediaan: 0,
    penjualan: 0,
    bebanPenjualan: 0,
    piutangUsaha: 0,
    hutangUsaha: 0,
    diskonPembelian: 0,
    diskonPenjualan: 0,
    tambahanPembelian: 0,
    tambahanPenjualan: 0
  }
}

export const namperAbsSlice = createSlice({
  name: 'namperAbs',
  initialState,
  reducers: {
    setNamperAbs: (state, action: PayloadAction<NamperAbs>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNamperAbs } = namperAbsSlice.actions

export default namperAbsSlice.reducer