import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import RadioButton from "../../app/common/RadioButton";
import agent from "../../app/api/agent";
import { Namper } from "../../app/models/namper";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TransactionBody } from "../../app/models/transaction";
import { FakturBody } from "../../app/models/faktur";
import { setLoading } from "../../app/stores/loadingSlice";
import { useDispatch } from "react-redux";
import { handleNumberTextFieldChange } from "../../app/util/numberTextFieldUtil";

export default function PencatatanBeban(){
    const [jenis, setJenis] = useState('operasional')
    const [ket, setKet] = useState<string>('')
    const [metode, setMetode] = useState('tunai')
    const [jumlah, setJumlah] = useState(0)
    const [jumlahString, setJumlahString] = useState('')
    const [bebans, setBebans] = useState<Namper[]>([])
    const [fBebans, setFBebans] = useState<Namper[]>([])
    const [bebanId, setBebanId] = useState(0)
    const [sumbers, setSumbers] = useState<Namper[]>([])
    const [sumberId, setSumberId] = useState<number>(0) // isinya namper kas, hutang, dll yg terpilih
    const [sumber, setSumber] = useState<Namper>() // kaya atasnya tapi dlm bentuk namper
    const [error, setError] = useState("")
    let kases = useRef<Namper[]>([])
    let hutangs = useRef<Namper[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
      const fetchData = async () => {
      try {
          const bs = (await agent.Nampers.list("beban")).data
          setBebans(bs)
          setFBebans(bs.filter(b => b.category === `beban ${jenis}`))

          kases.current = (await agent.Nampers.list("kas")).data
          hutangs.current = (await agent.Nampers.list("hutang")).data
          setSumbers(kases.current)
      } catch (error){
          console.log(error)
      }
      };
      dispatch(setLoading(true))
      fetchData();
      dispatch(setLoading(false))
  }, [])

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setError("")
        dispatch(setLoading(true))
        try{
          if(sumber === undefined || bebanId === 0 || jumlah === 0){
            setError("tolong lengkapi data")
          } else {
            if(metode === 'tunai' && (jumlah > sumber!.saldo)){
                setError(`total biaya ${jumlah.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} lebih besar dari saldo kas ${sumber!.saldo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
            } else {
                let kategori
                if(metode === 'tunai'){
                    kategori = "Pembayaran Beban Tunai"
                } else {
                    kategori = "Pencatatan Beban"
                }
                const transaction = await agent.Transactions.create(new TransactionBody(kategori,"",ket,jumlah,"debit",true,0,bebanId,Date(),null))
                const kreditTrans = await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,jumlah,"kredit",false,0,sumberId,transaction.data.createdAt,transaction.data.id))
                await agent.Fakturs.create(new FakturBody("Beban",transaction.data.id,[],[],[],0,"",jumlah,(metode === 'tunai' ? jumlah : 0),0,kreditTrans.data.namPerId))
                toast.success("transaksi berhasil dibuat")
                navigate('/home')
            }
        }
      } catch(error){
        // to do: set error
      }
      dispatch(setLoading(false))
    }
    
    const handleJenisChange = (option: string) => {
      setBebanId(0)
      setJenis(option)
      setFBebans(bebans.filter(b => b.category === `beban ${option}`))
    }

    const handleRadioChange = (value: string) => {
        setMetode(value)
        setSumberId(0)
        if(value === 'tunai'){
          setSumbers(kases.current)
        } else {
          setSumbers(hutangs.current)
        }
    }

    const handleSumberChange = (option: number) => {
      setSumberId(option)
      setSumber(sumbers.filter(item => item.id === option)[0])
    }

    return (
        <Container className="mt-5">
          <h1 className="mb-3">Pencatatan Beban:</h1>
          <div style={{color:'red'}}>{error}</div>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group controlId="formJenis">
                <Form.Label>Pilih jenis beban:</Form.Label>
                <Form.Select
                  value={jenis}
                  onChange={(e) => handleJenisChange(e.target.value)}
                >
                  <option value="operasional">operasional</option>
                  <option value="nonoperasional">nonoperasional</option>
                  <option value="pajak">pajak</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group controlId="formBeban">
                <Form.Label>Pilih Beban:</Form.Label>
                <Form.Select
                  value={bebanId}
                  onChange={(e) => setBebanId(parseInt(e.target.value))}
                >
                  <option value="0" disabled>pilih beban</option>
                  {fBebans.map((p, index) => (
                      <option key={index} value={p.id}>{p.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <RadioButton
              label="tunai"
              value="tunai"
              checked={metode === 'tunai'}
              onSelectionChange={handleRadioChange}
              />
              <RadioButton
              label="hutang"
              value="hutang"
              checked={metode === 'hutang'}
              onSelectionChange={handleRadioChange}
              />
            </Row>
              <Form.Group as={Col} controlId="formSumber">
                <Form.Label>Pilih sumber:</Form.Label>
                <Form.Select
                  value={sumberId}
                  onChange={(e) => handleSumberChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>pilih sumber</option>
                  {sumbers.map((p, index) => (
                      <option key={index} value={p.id}>{p.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="numericInput">
                  <Form.Label>Input Jumlah:</Form.Label>
                  <Form.Control
                  type="text"
                  placeholder="input jumlah"
                  value={jumlahString}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setJumlah, setJumlahString)}
                  />
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
          </Form>
        </Container>
    );
}