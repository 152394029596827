export interface Namper {
    id: number;
    category: string;
    name: string;
    saldo: number;
}

export interface NamperAbs {
    persediaan: number;
    penjualan: number;
    bebanPenjualan: number;
    piutangUsaha: number;
    hutangUsaha: number;
    diskonPembelian: number
    diskonPenjualan: number
    tambahanPembelian:number
    tambahanPenjualan: number
}

export class NamperBody {
    name:string = ""
    category:string = ""

    constructor(name: string, category: string) {
        this.name = name
        this.category = category
    }
}