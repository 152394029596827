import { useState, FormEvent } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import agent from '../../app/api/agent';
import { ProductBody } from '../../app/models/product';
import { useNavigate } from "react-router-dom";
import { ErrorResponse } from '../../app/models/errorResponse';

export default function CreateProductPage(){
    const [product, setProduct] = useState('');
    const navigate = useNavigate()
  
    const handleSubmit = async (event: FormEvent) => {
      event.preventDefault();
      try {
        await agent.Products.create(new ProductBody(product))
        navigate(-1)
        console.log("created product")
      }catch(error){
        console.log("unauthorized from react")
        console.log(error);
    }
    };
  
    return (
      <Container>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-3">
            <Form.Label>Product name:</Form.Label>
            <Form.Control
              placeholder="Enter product name"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
            />
          </Form.Group>
  
          <Button variant="primary" type="submit" className="mt-3">
            Create
          </Button>
        </Form>
      </Container>
    );
  };