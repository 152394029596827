// numberUtils.ts

export const formatNumberWithCommas = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
  export const convertToNumber = (formattedNumber: string): number => {
    if (formattedNumber === "") {
      return 0;
    } else {
      const numberString = formattedNumber.replace(/,/g, '');
      const numberValue = parseInt(numberString);
      return numberValue;
    }
  };
  
  export const handleNumberTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setJumlah: React.Dispatch<React.SetStateAction<number>>,
    setJumlahString: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const inputValue = convertToNumber(e.target.value);
    if (!isNaN(inputValue)) {
      setJumlah(inputValue); // Set numeric value
      const formattedValue = formatNumberWithCommas(inputValue);
      if (inputValue === 0) {
        setJumlahString("")
      } else {
        setJumlahString(formattedValue); // Set formatted string value
      }
    }
  };
  