import { Form } from 'react-bootstrap';

interface RadioButtonProps {
  label: string;
  value: string;
  onSelectionChange: (selectedValue: string) => void;
  checked: boolean;
}

function RadioButton({ label, value, onSelectionChange, checked }: RadioButtonProps) {
  const handleRadioChange = () => {
    onSelectionChange(value);
  };

  return (
    <Form.Check
      type="radio"
      label={label}
      name="radioGroup"
      value={value}
      checked={checked}
      onChange={handleRadioChange}
    />
  );
}

export default RadioButton;
