import { Button, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import React from 'react'

export default function NotFound() {
    return(
        <Container>
            <h1 className="mt-3 mb-5">Halaman Tidak Ditemukan</h1>
            <Link to="/home" className='mt-3'>
                <Button variant="primary">Kembali ke Home</Button>
            </Link>
        </Container>
    )
}