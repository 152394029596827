import { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { AddedProduct, Product } from "../../app/models/product";
import { handleNumberTextFieldChange } from "../../app/util/numberTextFieldUtil";

interface AddProductProps {
    products: Product[];
    tipe: string;
    onSelectionChange: (produk: AddedProduct) => void;
  }

export default function AddProductModal({ products, onSelectionChange, tipe }: AddProductProps){
    const [jumlah, setJumlah] = useState(0);
    const [jumlahString, setJumlahString] = useState('');
    const [harga, setHarga] = useState(0);
    const [hargaString, setHargaString] = useState('');
    const [produk, setProduk] = useState<Product>()
    const [hidden, setHidden] = useState<boolean>(true)
    const [hidden2, setHidden2] = useState<boolean>(true)

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if(jumlah === 0 || harga === 0 || produk === undefined){
            setHidden(false)
        } else {
            setHidden(true)
            if(tipe==="jual" && jumlah>produk.quantity){
              setHidden2(false)
            } else {
              setHidden2(true)
              onSelectionChange(new AddedProduct(produk.id, produk.name, jumlah, harga, produk.avg_price))
            }
        }
    }

    const handleProductChange = (option: number) => {
        const selectedProduct = products.find(product => product.id === option);
        setProduk(selectedProduct)
    }

    return (
        <Container>
          <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formSelect">
                <Form.Label>Pilih Produk:</Form.Label>
                <Form.Select
                  value={produk?.id ?? "0"}
                  onChange={(e) => handleProductChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {products.map((prod, index) => (
                        <option key={index} value={prod.id}>{prod.name} | jumlah:{prod.quantity}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            <Form.Group controlId="numericInput">
                <Form.Label>Jumlah:</Form.Label>
                <Form.Control
                type="text"
                placeholder="input jumlah"
                value={jumlahString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setJumlah, setJumlahString)}
                />
            </Form.Group>
            <Form.Group controlId="numericInput">
                <Form.Label>Harga per Barang:</Form.Label>
                <Form.Control
                type="text"
                placeholder="input harga"
                value={hargaString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setHarga, setHargaString)}
                />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Create
            </Button>
            <div hidden={hidden} style={{color:'red'}}>lengkapi data</div>
            <div hidden={hidden2} style={{color:'red'}}>jumlah yang diinput ({jumlah}) lebih besar dari stok ({produk?.quantity ?? 0})</div>
          </Form>
        </Container>
      );
}