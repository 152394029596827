import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ErrorServerState {
  value: string
}

const initialState: ErrorServerState = {
  value: ""
}

export const errorServerSlice = createSlice({
  name: 'errorServer',
  initialState,
  reducers: {
    setErrorServer: (state, action: PayloadAction<string>) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setErrorServer } = errorServerSlice.actions

export default errorServerSlice.reducer