import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { EditProductBody, Product, ProductBody, SuccessResponse } from '../models/product';
import { LoginBody, RegisterBody, User } from '../models/user';
import { ErrorResponse } from '../models/errorResponse';
import { Namper, NamperAbs, NamperBody } from '../models/namper';
import { Transaction, TransactionBody } from '../models/transaction';
import { Klien } from '../models/klien';
import { EditFakturBody, Faktur, FakturBody, FakturProduct } from '../models/faktur';
import { Cashflow, LabaRugi, Neraca } from '../models/laporan';
import { router } from '../router/Routes';
import { store } from '../stores/store';
import { setErrorServer } from '../stores/errorServerSlice';

axios.defaults.baseURL = 'https://akuntian.com/api';
// axios.defaults.baseURL = 'http://localhost:5000/api';

axios.interceptors.request.use(config => { //apa yang dilakukan saat api request
    const state = store.getState()
    // const user = useSelector((state: RootState) => state.user.value); 
    const user = state.user.value
    const token = user?.token
    if(token && config.headers) config.headers.Authorization = `Bearer ${token}`;
    return config;
}) 

axios.interceptors.response.use(async response => { // interceptor: apa yg dilakukan klo api selesai
    console.log('response axios:')
    console.log(response)
    return response
}, (error: AxiosError) => {
    const { data, status, request} = error.response as AxiosResponse;
    const errorMessage = `${request.responseURL} ${data.message}`
    store.dispatch(setErrorServer(errorMessage))
    const errorData = data as ErrorResponse
    if(status === 401){
        router.navigate('/')
        toast.error(errorData.message)
    } else if (status === 422){ // to do: klo dapet data array of string, return as array of string juga. (normalnya return dari backend bentuknya ValidationError[])
        toast.error('terjadi kesalahan, cek validitas data')
        if(typeof data[0] === 'string'){
            return Promise.reject(data);
        } else {
            let errMsg: string[] = []
            for (const errDat of errorData.data){
                errMsg.push(errDat.msg);
            }
            return Promise.reject(errMsg);
        }
    } else if (status === 403){
        toast.error('unauthorized')
    } else if (status === 404){
        toast.error('not found')
        router.navigate('/not-found')
    } else {
        router.navigate('/server-error')
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data; // return response.datanya

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

// method2 api
const Products = {
    list: () => requests.get<SuccessResponse<Product[]>>('/products/?userId=1'),
    create: (body: ProductBody) => requests.post('/products',body), // send activity object
    edit: (body: EditProductBody, id: number) => requests.put(`/products/${id}`,body)
//     details: (id: string) => requests.get<Activity>(`/activities/${id}`),
//     create: (activity: Activity) => requests.post('/activities',activity),
//     update: (activity: Activity) => requests.put(`/activities/${activity.id}`,activity),
//     delete: (id: string) => requests.del(`/activities/${id}`)
}

const Users = {
    login: (body: LoginBody) => requests.post<SuccessResponse<User>>('/auth/login',body),
    register: (body: RegisterBody) => requests.post('/auth/register',body),
    registerAdmin: (body: RegisterBody) => requests.post<SuccessResponse<User>>('/auth/registerAdmin',body)
}

const Kliens = {
    list:(kateg: string) => requests.get<SuccessResponse<Klien[]>>(`/kliens/?type=${kateg}`),
    create: (body: ProductBody, type: string) => requests.post(`/kliens/${type}`,body)
}
const Nampers = {
    list:(kateg: string) => requests.get<SuccessResponse<Namper[]>>(`/nampers/${kateg}`),
    get:() => requests.get<SuccessResponse<NamperAbs>>(`/nampers/absolute`),
    create:(body: NamperBody) => requests.post('/nampers',body)
}

const Transactions = {
    get: (startDate: Date, endDate: Date) => requests.get<SuccessResponse<Transaction[]>>(`/transactions?startDate=${startDate}&endDate=${endDate}`),
    getLast: () => requests.get<SuccessResponse<Transaction>>('/transactions/last'),
    create: (body: TransactionBody) => requests.post<SuccessResponse<Transaction>>('/transactions',body),
    delete: (noTransaksi: string) => requests.del(`/transactions/${noTransaksi}`)
}

const Fakturs = {
    list:(category: string, startDate: Date, endDate: Date) => requests.get<SuccessResponse<Faktur[]>>(`/fakturs?category=${category}&startDate=${startDate}&endDate=${endDate}`),
    unpaid:(category: string) => requests.get<SuccessResponse<Faktur[]>>(`/fakturs/unpaid/${category}`),
    detail:(id: number) => requests.get<SuccessResponse<FakturProduct[]>>(`/fakturs/${id}`),
    create: (body: FakturBody) => requests.post('/fakturs',body),
    edit: (body: EditFakturBody) => requests.put('/fakturs',body)
}

const Laporans = {
    neraca: (startDate: Date, endDate: Date) => requests.get<SuccessResponse<Neraca[]>>(`/laporan/neraca?startDate=${startDate}&endDate=${endDate}`),
    labaRugi: (startDate: Date, endDate: Date) => requests.get<SuccessResponse<LabaRugi[]>>(`/laporan/labarugi?startDate=${startDate}&endDate=${endDate}`),
    cashflow: (startDate: Date, endDate: Date) => requests.get<SuccessResponse<Cashflow>>(`/laporan/cashflow?startDate=${startDate}&endDate=${endDate}`),
}

const agent  = {
    Products,
    Users,
    Kliens,
    Nampers,
    Transactions,
    Fakturs,
    Laporans
}

export default agent;