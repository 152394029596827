import { useEffect, useState } from 'react';
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import agent from '../../app/api/agent';
import { Product } from '../../app/models/product';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/stores/store';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '../../app/stores/loadingSlice';
import { useDispatch } from 'react-redux';

export default function ProductListPage(){
    const navigate = useNavigate()
  const [products, setProducts] = useState<Product[]>([]);  
  const userValue = useSelector((state: RootState) => state.user.value); 
    const dispatch = useDispatch()
    
    useEffect(() => {
        const fetchData = async () => {
        try {
            const produks = await agent.Products.list()
            console.log('passed:')
            console.log(produks.data)
            setProducts(produks.data)
        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
    }, [userValue])

    return(
        <Container>
        <Button className='mt-3' variant="primary" onClick={() => navigate('/createProduct')}>buat produk baru</Button>
        <ListGroup className='mt-3'>
          <ListGroup.Item key="header" className="font-weight-bold d-flex justify-content-between align-items-center">
          <Container>
        <Row>
            <Col xs={6}>
                <span className="fw-bold">Name</span>
            </Col>
            <Col xs={3}>
                <span className="fw-bold">Jumlah</span>
            </Col>
            <Col xs={3}>
                <span className="fw-bold">Rata-rata Harga</span>
            </Col>
        </Row>
        </Container>
    </ListGroup.Item>
      {products.map((p) => (
        <ListGroup.Item key={p.id} className="d-flex justify-content-between align-items-center">
          <Container>
          <Row>
              <Col xs={6}>
                  <span>{p.name}</span>
              </Col>
              <Col xs={3}>
                  <span className="d-inline-block">{p.quantity}</span>
              </Col>
              <Col xs={3}>
              <span className="d-inline-block">{(p.avg_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            </Col>
          </Row>
        </Container>
        </ListGroup.Item>
      ))}
    </ListGroup>
    </Container>
    )
}