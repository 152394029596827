import { FormEvent, useEffect, useState } from "react";
import { Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { Klien } from "../../app/models/klien";
import { AddedProduct, EditProductBody, Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import AddProductModal from "../product/AddProductModal";
import RadioButton from "../../app/common/RadioButton";
import { Namper } from "../../app/models/namper";
import { TransactionBody } from "../../app/models/transaction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/stores/store";
import { FakturBody } from "../../app/models/faktur";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../app/stores/loadingSlice";

export default function FakturPenjualan(){
    const [showModal, setShowModal] = useState<boolean>(false);
    const [customers, setCustomers] = useState<Klien[]>([])
    const [selectedCust, setSelectedCust] = useState<number>(0)
    const [ket, setKet] = useState<string>('')
    const [metode, setMetode] = useState('tunai')
    const [products, setProducts] = useState<Product[]>([])
    const [passedProducts, setPassedProducts] = useState<Product[]>([])
    const [addedProducts, setAddedProducts] = useState<AddedProduct[]>([])
    const [tujuans, setTujuans] = useState<Namper[]>([])
    const [tujuanId, setTujuanId] = useState<number>(0)
    const [totBuyPrice, setTotBuyPrice] = useState<number>(0)
    const [totSellPrice, setTotSellPrice] = useState<number>(0)
    const [tambahan, setTambahan] = useState<string>("")
    const [tambahanKet, setTambahanKet] = useState<string>("")
    const [errors,setErrors] = useState<string[]>([])
    const namperAbs = useSelector((state: RootState) => state.namperAbs.value); 
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
        try {
            const produks = await agent.Products.list()
            setProducts(produks.data)
            setPassedProducts(produks.data)

            const customerzs = await agent.Kliens.list('customer')
            setCustomers(customerzs.data)

            const furom = await agent.Nampers.list('kas')
            setTujuans(furom.data)
        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
        setAddedProducts([])
        setErrors([])
        setTotSellPrice(0)
        setTotBuyPrice(0)
    }, [])

    const handleRadioChange = async (value: string) => {
        setMetode(value)
        if(value === 'piutang'){
            setTujuanId(namperAbs.piutangUsaha)
        } else {
            setTujuanId(0)
        }
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setErrors([])
        let tempErrors: string[] = []
        if (!/^-?\d*\.?\d*$/.test(tambahan)) {
            tempErrors.push("input nominal angka yang valid")
        }
        if(tujuanId === 0 || addedProducts.length === 0 || selectedCust === 0){
            tempErrors.push("tolong lengkapi data")
        } 
        if(tempErrors.length === 0){
            let kategori
            let tempTujuanId
            let paidValue
            const numTambahan = Number(tambahan)
            const tototPrice = totSellPrice+numTambahan
            if(metode === 'tunai'){
                kategori = "Penjualan Tunai"
                tempTujuanId = tujuanId
                paidValue = tototPrice
            } else {
                kategori = "Faktur Penjualan"
                tempTujuanId = namperAbs.piutangUsaha
                paidValue = 0
            }
            dispatch(setLoading(true))
            try {
                const transaction = await agent.Transactions.create(new TransactionBody(kategori,"",ket,totSellPrice,"kredit",true,selectedCust,namperAbs.penjualan,Date(),null))
                if(numTambahan<0){
                    await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,-numTambahan,"debit",true,selectedCust,namperAbs.diskonPenjualan,transaction.data.createdAt,null))
                }
                if(numTambahan>0){
                    await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,numTambahan,"kredit",true,selectedCust,namperAbs.tambahanPenjualan,transaction.data.createdAt,null))
                }
                const debitTrans = await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,tototPrice,"debit",false,selectedCust,tempTujuanId,transaction.data.createdAt,transaction.data.id))
                await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,totBuyPrice,"debit",true,selectedCust,namperAbs.bebanPenjualan,transaction.data.createdAt,null))
                await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,totBuyPrice,"kredit",false,selectedCust,namperAbs.persediaan,transaction.data.createdAt,null))
                for (const prod of addedProducts){
                    await agent.Products.edit(new EditProductBody(-prod.quantity,0),prod.id)
                }
                await agent.Fakturs.create(new FakturBody("Penjualan",transaction.data.id,addedProducts.map(product => product.id),addedProducts.map(product => product.quantity),addedProducts.map(product => product.price),
                Number(tambahan),tambahanKet,tototPrice,paidValue,selectedCust, debitTrans.data.namPerId))
                toast.success("transaksi berhasil dibuat")
                navigate('/home')
            } catch(error){
                console.log(error)
            }
            dispatch(setLoading(false))
        } else {
            setErrors(tempErrors)
        }
            
    }

    const handleCustomerChange = (option: number) => {
        setSelectedCust(option)
    }

    const handleTujuanChange = (option: number) => {
        setTujuanId(option)
    }

    const handleAddedProduct = (produk: AddedProduct) => {
        setShowModal(false);
        setAddedProducts(prevArr => [...prevArr, produk])
        setTotSellPrice(value => value+produk.totPrice)
        setTotBuyPrice(value => value+(produk.avgPrice*produk.quantity))
        setPassedProducts(prevArr => prevArr.filter(item => item.id !== produk.id) )       
    }

    const removeAddedProduct = (id:number) => {
        const delProduk = addedProducts.find(p => p.id === id)
        setTotSellPrice(value => value-delProduk!.totPrice)
        setTotBuyPrice(value => value-(delProduk!.avgPrice*delProduk!.quantity))
        setAddedProducts(prevArr => prevArr.filter(item => item.id !== id))
        const toAdd = products.find(p => p.id === id)
        setPassedProducts(prevArr => [...prevArr,toAdd!])
    }

    const handleModalClose = () => {
        setShowModal(false);
      };
    
    return (
        <Container className="mt-5">
            <h1 className="mb-3">Faktur Penjualan:</h1>
            {errors.map((er, index) => (
                <div key={index} style={{color:'red'}}>{er}</div>
            ))}
            <Form onSubmit={handleSubmit}>
            <Form.Group as={Col} controlId="formSupplier" className="mb-3">
                <Form.Label>Pilih Customer:</Form.Label>
                <Form.Select
                  value={selectedCust}
                  onChange={(e) => handleCustomerChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {customers.map((c, index) => (
                        <option key={index} value={c.id}>{c.nama}</option>
                    ))}
                </Form.Select>
            </Form.Group>
                <Row className="mb-3">
                    <RadioButton
                    label="tunai"
                    value="tunai"
                    checked={metode === 'tunai'}
                    onSelectionChange={handleRadioChange}
                    />
                    <RadioButton
                    label="piutang"
                    value="piutang"
                    checked={metode === 'piutang'}
                    onSelectionChange={handleRadioChange}
                />
                </Row>
            <Row className="mb-3" hidden={metode==="piutang"}>
              <Form.Group as={Col} controlId="formTujuan">
                <Form.Label>Pilih Tujuan:</Form.Label>
                <Form.Select
                  value={tujuanId}
                  onChange={(e) => handleTujuanChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {tujuans.map((t, index) => (
                        <option key={index} value={t.id}>{t.name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Button className="mb-3" onClick={() => setShowModal(true)}>Tambahkan Produk</Button>
            <ListGroup>
                <ListGroup.Item key="header" className="font-weight-bold d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col>
                        <span className="fw-bold">Name</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Quantity</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Harga per Produk</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Total per Produk</span>
                    </Col>
                </Row>
                </Container>
            </ListGroup.Item>
            {addedProducts.map((p) => (
                <ListGroup.Item key={p.id} className="d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={3}>
                        <span>{p.name}</span>
                    </Col>
                    <Col xs={3}>
                        <span>{p.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={3}>
                        <span>Rp {p.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={2}>
                        <span>Rp {p.totPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={1}>
                    <Button variant="outline-danger" onClick={() => removeAddedProduct(p.id)}>
                    Delete
                    </Button>
                </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            ))}
            </ListGroup>
            <Form.Group controlId="numberInput" className="mt-3">
                <Form.Label>Input nominal tambahan: (diskon, additional charge, dsb yang mengubah nilai akhir hutang)</Form.Label>
                <Form.Control
                type="text"
                placeholder="input angka >0 untuk menambahkan, <0 untuk mengurangi"
                value={tambahan}
                onChange={(e) => setTambahan(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="kettamInput" className="mt-3">
                <Form.Label>Input keterangan tambahan:</Form.Label>
                <Form.Control
                type="text"
                placeholder="keterangan tambahan"
                value={tambahanKet}
                onChange={(e) => setTambahanKet(e.target.value)
                }
                />
            </Form.Group>
            <Row className="mt-2">
                <Col xs={9}>
                <Button variant="primary" type="submit">Submit</Button>
                </Col>
                <Col xs={3}>
                    <span>total: Rp {(totSellPrice+Number(tambahan)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </Col>
            </Row>
            </Form>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                <Modal.Title>Modal Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <AddProductModal products={passedProducts} onSelectionChange={handleAddedProduct} tipe="jual"/>
                </Modal.Body>
            </Modal>
        </Container>
    )
}