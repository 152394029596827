import { FormEvent, useEffect, useState } from "react";
import agent from "../app/api/agent";
import { Namper } from "../app/models/namper";
import { Button, Container, Form } from "react-bootstrap";
import { TransactionBody } from "../app/models/transaction";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setLoading } from "../app/stores/loadingSlice";
import { useDispatch } from "react-redux";
import { handleNumberTextFieldChange } from "../app/util/numberTextFieldUtil";

export default function TransferDana(){
    const [kas, setKas] = useState<Namper[]>([])
    const [fromId,setFromId] = useState(0)
    const [toId,setToId] = useState(0)
    const [ket,setKet] = useState("")
    const [jumlah,setJumlah] = useState(0)
    const [jumlahString,setJumlahString] = useState('')
    const navigate = useNavigate()
    const [errors,setErrors] = useState<string[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
        try {
            const kass = (await agent.Nampers.list('kas')).data
            setKas(kass)
        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
    }, [])

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        dispatch(setLoading(true))
        try {
        const transaction = await agent.Transactions.create(new TransactionBody("Transfer Dana","",ket,jumlah,"kredit",false,0,fromId,Date(),null))
        await agent.Transactions.create(new TransactionBody("Transfer Dana",transaction.data.noTransaksi,ket,jumlah,"debit",false,0,toId,transaction.data.createdAt,null))
        toast.success("transaksi berhasil dibuat")
        navigate('/home')
        } catch(error){
            if(Array.isArray(error)){
                setErrors(error)
              } 
            console.log(error)
        }
        dispatch(setLoading(false))
    }

    return (
        <Container className="mt-5">
            <h1 className="mb-3">Transfer Dana:</h1>
            {errors.map((er, index) => (
                <div key={index} style={{color:'red'}}>{er}</div>
            ))}
            <Form onSubmit={handleSubmit}>
            <Form.Group controlId="fromKas" className="mb-3">
                <Form.Label>Pilih Kas Asal:</Form.Label>
                <Form.Select
                  value={fromId}
                  onChange={(e) => setFromId(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {kas.map((l, index) => (
                        <option key={index} value={l.id}>{l.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="formKas" className="mb-3">
                <Form.Label>Pilih Kas Tujuan:</Form.Label>
                <Form.Select
                  value={toId}
                  onChange={(e) => setToId(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {kas.map((k, index) => (
                        <option key={index} value={k.id}>{k.name}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="formKet" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="formJum">
                <Form.Label>Jumlah:</Form.Label>
                <Form.Control
                type="text"
                placeholder="input jumlah"
                value={jumlahString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setJumlah, setJumlahString)}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
            </Form>
        </Container>
    )
}