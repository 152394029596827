import { Button, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../app/stores/store";

export default function ServerError() {
    const errorCause = useSelector((state: RootState) => state.errorServer.value); 

    return (
        <Container>
            <h1 className="mt-3 mb-1">Internal Server Error</h1>
            <div className="mb-5">mohon hubungi pengembang</div>
            <div className="mb-5">{errorCause}</div>
            <Link to="/home" className='mt-3'>
                <Button variant="primary">Kembali ke home</Button>
            </Link>
        </Container>
    )
}