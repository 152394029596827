export interface SuccessResponse<T> {
    message: string;
    data: T;
}

export interface Product {
    id: number;
    name: string;
    quantity: number;
    avg_price: number;
}

export class ProductBody {
    name:string = ""

    constructor(name: string) {
        this.name=name
    }
}

export class AddedProduct {
    id: number = 0;
    name:string = "";
    quantity: number = 0
    price: number = 0
    avgPrice: number = 0
    totPrice: number = 0

    constructor(id: number, name: string, quantity: number, price: number, avgPrice: number) {
        this.id = id
        this.name = name
        this.quantity = quantity
        this.price = price
        this.avgPrice = avgPrice
        this.totPrice = quantity * price
    }
}

export class EditProductBody{
    quantity: number = 0
    price: number = 0

    constructor(quantity: number, price:number){
        this.quantity = quantity
        this.price = price
    }
}