import React, { useState, FormEvent, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import agent from '../../app/api/agent';
import { LoginBody, RegisterBody } from '../../app/models/user';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setUser } from '../../app/stores/userSlice'
import { setNamperAbs } from '../../app/stores/namperSlice';
import { NamperBody } from '../../app/models/namper';
import { setLoading } from '../../app/stores/loadingSlice';

export default function LoginPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [errors, setErrors] = useState<string[]>([])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    dispatch(setLoading(true))
    try {
      let user;
      if (isLogin) {
        user = await agent.Users.login(new LoginBody(email, password))
        dispatch(setUser(user.data))
      } else {
        user = await agent.Users.registerAdmin(new RegisterBody(name, email, password, password2, ""))
        dispatch(setUser(user.data))
        await agent.Nampers.create(new NamperBody("penjualan", "pendapatan operasional"))
        await agent.Nampers.create(new NamperBody("persediaan", "persediaan"))
        await agent.Nampers.create(new NamperBody("beban pokok penjualan", "beban pokok penjualan"))
        await agent.Nampers.create(new NamperBody("piutang usaha", "piutang usaha"))
        await agent.Nampers.create(new NamperBody("hutang usaha", "hutang usaha"))
        await agent.Nampers.create(new NamperBody("diskon pembelian", "pendapatan operasional"))
        await agent.Nampers.create(new NamperBody("diskon penjualan", "beban operasional"))
        await agent.Nampers.create(new NamperBody("tambahan pembelian", "beban operasional"))
        await agent.Nampers.create(new NamperBody("tambahan penjualan", "pendapatan operasional"))
      }
      console.log("token : " + user.data.token)
      const namperAbs = await agent.Nampers.get()
      dispatch(setNamperAbs(namperAbs.data))
      navigate('/home')
    } catch (error) {
      console.log(error);
      if (Array.isArray(error)) {
        setErrors(error)
      }
    }
    dispatch(setLoading(false))
  };

  const handleChangeLogin = () => {
    setIsLogin(!isLogin)
    setErrors([])
  }

  useEffect(() => {
    dispatch(setLoading(false))
},[])

  return (
    <Container>
      <h1 className='mt-3'>Akuntian v1.1</h1>
      <h2>{isLogin ? "Login" : "Register"}</h2>
      {errors.map((er, index) => (
        <div key={index} style={{ color: 'red' }}>{er}</div>
      ))}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mt-3" hidden={isLogin}>
          <Form.Label>nama</Form.Label>
          <Form.Control
            placeholder=""
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="mt-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formConfirm" className="mt-3" hidden={isLogin}>
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          {isLogin ? "Login" : "Daftar"}
        </Button>
      </Form>
      <Button variant="link" onClick={handleChangeLogin}>
        {isLogin ? "Tidak punya akun? klik untuk daftar" : "Sudah punya akun? klik untuk login"}
      </Button>
    </Container>
  );
};