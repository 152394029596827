import { FormEvent, useEffect, useState } from "react"
import { Button, Container, Form } from "react-bootstrap"
import agent from "../../app/api/agent"
import { EditFakturBody, Faktur } from "../../app/models/faktur"
import { formatDate } from "../../app/util/dateUtil"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { TransactionBody } from "../../app/models/transaction"
import { useSelector } from "react-redux"
import { RootState } from "../../app/stores/store"
import { Namper } from "../../app/models/namper"
import { setLoading } from "../../app/stores/loadingSlice"
import { useDispatch } from "react-redux"
import { handleNumberTextFieldChange } from "../../app/util/numberTextFieldUtil";

// list perbedaan jual dan beli:
// faktur id hrs yg jual ato beli
// bikin transaksi hrs pilih antara pembayara ato penjualan
interface PembayaranProps{
    category: string
}
export default function Pembayaran({category}: PembayaranProps){
    const [paidValue, setPaidValue] = useState<number>(0);
    const [paidValueString, setPaidValueString] = useState<string>('');
    const [fakturs, setFakturs] = useState<Faktur[]>([]);
    const [fakturId, setFakturId] = useState<number>(0);
    const [faktur, setFaktur] = useState<Faktur>();
    const [ket, setKet] = useState<string>('')
    const [sumbers, setSumbers] = useState<Namper[]>([])
    const [sumberId, setSumberId] = useState<number>(0) // isinya namper kas, hutang, dll yg terpilih
    const [sumber, setSumber] = useState<Namper>() // kaya atasnya tapi dlm bentuk namper
    const [errors,setErrors] = useState<string>("")
    const navigate = useNavigate()
    const namperAbs = useSelector((state: RootState) => state.namperAbs.value); 
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchData = async () => {
        try {
            const fakturs = await agent.Fakturs.unpaid(category)
            setFakturs(fakturs.data)

            const furom = await agent.Nampers.list('kas')
            setSumbers(furom.data)
        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
    },[])

    const handleFromChange = (option: number) => {
        setSumberId(option)
        setSumber(sumbers.filter(item => item.id === option)[0])
    }

    const handleFakturChange = (option:number) => {
        setFakturId(option)
        setFaktur(fakturs.filter(item => item.id === option)[0])
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()
        setErrors("")
        if(fakturId !== 0 && paidValue !== 0 && sumberId !== 0){
            if(sumber !== undefined && (category === "Beban" || category === "Pembelian") && (paidValue > sumber!.saldo)){
                setErrors(`total biaya ${paidValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} lebih besar dari saldo ${sumber!.name} ${sumber!.saldo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
            } else {
                dispatch(setLoading(true))
                try{
                    const newpaidValue = Math.min(paidValue,faktur!.sisa,)
                    await agent.Fakturs.edit(new EditFakturBody(fakturId, newpaidValue))
                    if(category === "Pembelian"){
                        const transaction = await agent.Transactions.create(new TransactionBody("Pembayaran Pembelian","",ket,newpaidValue,"debit",false,faktur!.Klien.id,namperAbs.hutangUsaha,Date(),null))
                        await agent.Transactions.create(new TransactionBody("Pembayaran Pembelian",transaction.data.noTransaksi,ket,newpaidValue,"kredit",false,faktur!.Klien.id,sumberId,transaction.data.createdAt,faktur!.Transaction.id))    
                    } else if(category === "Penjualan"){
                        const transaction = await agent.Transactions.create(new TransactionBody("Penerimaan Penjualan","",ket,newpaidValue,"kredit",false,faktur!.Klien.id,namperAbs.piutangUsaha,Date(),null))    
                        await agent.Transactions.create(new TransactionBody("Penerimaan Penjualan",transaction.data.noTransaksi,ket,newpaidValue,"debit",false,faktur!.Klien.id,sumberId,transaction.data.createdAt,faktur!.Transaction.id))
                    } else if(category === "Beban"){
                        const transaction = await agent.Transactions.create(new TransactionBody("Pembayaran Beban","",ket,newpaidValue,"debit",false,0,faktur!.NamaPerkiraan.id,Date(),null))
                        await agent.Transactions.create(new TransactionBody("Pembayaran Beban",transaction.data.noTransaksi,ket,newpaidValue,"kredit",false,0,sumberId,transaction.data.createdAt,faktur!.Transaction.id))    
                     }else if(category === "Pendapatan"){
                        const transaction = await agent.Transactions.create(new TransactionBody("Penerimaan Pendapatan","",ket,newpaidValue,"kredit",false,0,faktur!.NamaPerkiraan.id,Date(),null))
                        await agent.Transactions.create(new TransactionBody("Penerimaan Pendapatan",transaction.data.noTransaksi,ket,newpaidValue,"debit",false,0,sumberId,transaction.data.createdAt,faktur!.Transaction.id))    
                    }
                    toast.success("transaksi berhasil dibuat")
                    navigate('/home')
                } catch(error){
                    console.log(error)
                }
                dispatch(setLoading(false))
            }
        } else {
            setErrors("input semua data")
        }
    }

    return (
        <Container className="mt-5">
        <h1 className="mb-3">{category === "Penjualan" || category === "Pendapatan" ? `Penerimaan ${category}` : `Pembayaran ${category}`}</h1>
        <div style={{color:'red'}}>{errors}</div>
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="faktur" className="mb-3">
                <Form.Label>Pilih {category === "Penjualan" || category === "Pendapatan" ? "Piutang" : "Hutang"}:</Form.Label>
                <Form.Select
                  value={fakturId}
                  onChange={(e) => handleFakturChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>pilih faktur</option>
                  {fakturs.map((f, index) => (
                        <option key={index} value={f.id}>{f.noFaktur ?? "0"}-{(f.Klien != null) ? f.Klien.nama : f.NamaPerkiraan.name}-{(f.sisa).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                    ))}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="kas">
                <Form.Label>Pilih Kas:</Form.Label>
                <Form.Select
                  value={sumberId}
                  onChange={(e) => handleFromChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {sumbers.map((f, index) => (
                        <option key={index} value={f.id}>{f.name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            <Form.Group controlId="value" className="mb-3">
                <Form.Label>Input jumlah:</Form.Label>
                <Form.Control
                type="text"
                placeholder="input jumlah"
                value={paidValueString}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberTextFieldChange(e, setPaidValue, setPaidValueString)}
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Button variant="primary" type="submit">Submit</Button>
        </Form>
        </Container>
    )
}