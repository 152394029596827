export interface Klien {
    id: number;
    tipe: string;
    nama: string;
    alamat: string;
}

export class KlienBody {
    name:string = ""
    alamat:string = ""

    constructor(name: string, alamat: string) {
        this.name = name
        this.alamat = alamat
    }
}