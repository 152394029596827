import { Klien } from "./klien"
import { Namper } from "./namper"
import { Product } from "./product"
import { Transaction } from "./transaction"

export interface Faktur{
    id: number
    Klien: Klien
    Transaction: Transaction
    NamaPerkiraan: Namper
    category: string
    totalValue: number
    paidValue: number
    addValue: number
    addKet: string
    sisa: number
    createdAt: Date
    noFaktur: string
}

export class FakturBody{
    category: string = ""
    transactionId: number = 0
    productIds: number[] = []
    quantities: number[] = []
    prices: number[] = []
    addValue: number = 0
    addKet: string = ''
    totalValue: number = 0
    paidValue: number = 0
    klienId: number = 0
    sumberId: number = 0

    constructor(category: string, transactionId: number, productIds: number[], 
        quantities: number[], prices: number[], addValue: number, addKet: string, 
        totalValue: number, paidValue: number, klienId: number, sumberId: number) {
        this.category = category
        this.transactionId = transactionId
        this.productIds = productIds
        this.quantities = quantities
        this.prices = prices
        this.addValue = addValue
        this.addKet = addKet
        this.totalValue = totalValue
        this.paidValue = paidValue
        this.klienId = klienId
        this.sumberId = sumberId
    }
}

export interface FakturProduct{
    quantity: number
    price: number
    total: number
    createdAt: Date
    Faktur: Faktur
    Product: Product
}

export class EditFakturBody{
    fakturId: number
    paidValue: number

    constructor(fakturId: number, paidValue: number){
        this.fakturId = fakturId
        this.paidValue = paidValue
    }
}