import { useState } from "react";
import * as excelJS from "exceljs";
import { saveAs } from "file-saver";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import agent from "../app/api/agent";
import { Faktur } from "../app/models/faktur";

export default function FakturListPage(){
    // State for date pickers
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [category, setCategory] = useState<string>("pembelian");
  const [fakturs, setFakturs] = useState<Faktur[]>([]);
  let timeZoneOffsetInMinutes: number

  const handleGetFaktur = async () => {
    if(startDate !== null && endDate !== null){
      try{
      timeZoneOffsetInMinutes = startDate.getTimezoneOffset();
      startDate.setHours(0,0,0,0)
      endDate.setHours(23,59,59,0)
      const faks = await agent.Fakturs.list(category, new Date(startDate.getTime() + timeZoneOffsetInMinutes * 60000), new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
      setFakturs(faks.data)
      } catch(error) {
        console.log(error)
      }
    }
  };

  const formatDate = (date: Date): string => {
    const newDate = new Date(date)
    const formattedDate = newDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric'});
    return formattedDate
  }

  const downloadFaktur = async (id: number) => {
    try{
      const fp = (await agent.Fakturs.detail(id)).data
      let workBook = new excelJS.Workbook()
      const sheet = workBook.addWorksheet("faktur")
      sheet.mergeCells('B2', 'F2');
      const cell = sheet.getCell('B2')
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      cell.font = {
          underline: true,
          bold: true
        };
      if(fp.length>0){
        cell.value = `Faktur ${fp[0].Faktur.category}`
        sheet.columns = [
          { key: '', width:5},
          { key: 'no', width:15},
          { key: 'nama', width:10},
          { key: 'jumlah', width:10},
          { key: 'harga', width:15},
          { key: 'total', width:15}
        ]
        sheet.getColumn(5).numFmt = '#,##0';
        sheet.getColumn(6).numFmt = '#,##0';
        sheet.getRow(4).values = ['','Nomor Faktur:', '', '', '', fp[0].Faktur.noFaktur ?? "-"];
        sheet.getRow(5).values = ['','Tanggal:', '', '', '', formatDate(fp[0].createdAt)]; // tidak perlu sesuaikan dengan timezoneoffset. udah bener nunjukin tanggal indo krn dia pake new Date()
        sheet.getRow(6).values = ['',(fp[0].Faktur.category === 'Pembelian' ? 'Supplier:' : 'Pelanggan:'), '', '', '', fp[0].Faktur.Klien.nama];
        sheet.getRow(7).values = ['',(fp[0].Faktur.category === 'Pembelian' ? 'Alamat Supplier:' : 'Alamat Pelanggan:'), '', '', '', fp[0].Faktur.Klien.alamat ?? "-"];
        sheet.getRow(9).values = ['','No', 'Produk', 'Jumlah', 'Harga per Produk', 'Total per Produk'];
        fp.forEach(function(item, index) {
            sheet.addRow({
              no: index+1,
              nama: item.Product.name,
              jumlah: item.quantity,
              harga: item.price,
              total: item.total
            })
        })
        sheet.addRows([{},{
          no: 'Pendapatan/Biaya:',
          total: fp[0].Faktur.addValue
        },{
          no: fp[0].Faktur.addKet
        },{

        },{
          no: 'Total:',
          total: fp[0].Faktur.totalValue
        },{},{
          no: 'Jumlah Lunas:',
          total: fp[0].Faktur.paidValue
        },{
          no: (fp[0].Faktur.category === 'Pembelian' ? 'Hutang:' : 'Piutang:'),
          total: (fp[0].Faktur.totalValue - fp[0].Faktur.paidValue)
        }])
      } else {
        const fakt = fakturs.filter(f => f.id === id)[0]
        cell.value = `Faktur ${fakt.category}`
        sheet.columns = [
          { key: '', width:5},
          { key: 'no', width:15},
          { key: '', width:10},
          { key: '', width:10},
          { key: '', width:15},
          { key: 'total', width:15}
        ]
        sheet.getColumn(5).numFmt = '#,##0';
        sheet.getColumn(6).numFmt = '#,##0';
        sheet.getRow(4).values = ['','Nomor Faktur:', '', '', '', fakt.noFaktur ?? "-"];
        sheet.getRow(5).values = ['','Tanggal:', '', '', '', formatDate(fakt.createdAt)];
        sheet.addRows([{},{
          no: fakt.Transaction.NamaPerkiraan.name,
        },{
          no: 'Total:',
          total: fakt.totalValue
        },{},{
          no: 'Jumlah Lunas:',
          total: fakt.paidValue
        },{
          no: (fakt.category === 'Beban' ? 'Hutang:' : 'Piutang:'),
          total: (fakt.totalValue - fakt.paidValue)
        }])
      }
      workBook.xlsx.writeBuffer().then(function(buffer) {
        // done
        console.log(buffer);
    
        const blob = new Blob([buffer], { type: "applicationi/xlsx" });
        saveAs(blob, "faktur.xlsx");
      });
  } catch(error){
      console.log(error)
  }
  }

    return(
        <Container>
        <h1 className="mt-3">List Faktur:</h1>
        <Row className='mt-3'>
            <Col>
                <label>Start Date:</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            </Col>
            <Col>
                <label>End Date:</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </Col>
        </Row>
        <Row className="gx-5">
            <Col xs={6}>
            <label>Pilih kategori:</label>
            <select className="form-select"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="pembelian">pembelian</option>
            <option value="penjualan">penjualan</option>
            <option value="beban">beban</option>
            <option value="pendapatan">pendapatan</option>
          </select>
            </Col>
        </Row>
        <Button variant="primary" onClick={handleGetFaktur} className="mt-3">
            Cari Faktur
        </Button>

        <ListGroup>
                <ListGroup.Item key="header" className="font-weight-bold d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={3}>
                        <span className="fw-bold">Nomor Faktur</span>
                    </Col>
                    <Col xs={3}>
                        <span className="fw-bold">Klien</span>
                    </Col>
                    <Col xs={3}>
                        <span className="fw-bold">Jumlah</span>
                    </Col>
                    <Col xs={3}>
                        <span className="fw-bold">{(category === 'beban' || category === 'pembelian') ? 'Hutang' : 'Piutang'}</span>
                    </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            {fakturs.map((p) => (
                <ListGroup.Item key={p.id} className="d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={3}>
                        <span>{p.noFaktur ?? "-"}</span>
                    </Col>
                    <Col xs={3}>
                        <span>{p.Klien?.nama ?? "-"}</span>
                    </Col>
                    <Col xs={3}>
                        <span>{p.totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{(p.totalValue-p.paidValue).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={1}>
                    <Button variant="success" onClick={() => downloadFaktur(p.id)}>
                    Download
                    </Button>
                </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            ))}
        </ListGroup>
        </Container>
    )
}