import { useEffect, useState } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import agent from "../app/api/agent";
import { Transaction } from "../app/models/transaction";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { setLoading } from "../app/stores/loadingSlice";
import { useDispatch } from "react-redux";

export default function TransactionListPage(){
  // State for date pickers
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  // State for picker with choices
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [lastTrans, setLastTrans] = useState<string>("")
  const navigate = useNavigate()
const dispatch = useDispatch()

  const handleGetTransactions = async () => {
    dispatch(setLoading(true))
    if(startDate !== null && endDate !== null){
      try{
    const timeZoneOffsetInMinutes = startDate.getTimezoneOffset();
      startDate.setHours(0,0,0,0)
      endDate.setHours(23,59,59,0)
      const trans = await agent.Transactions.get(new Date(startDate.getTime() + timeZoneOffsetInMinutes * 60000), new Date(endDate.getTime() + timeZoneOffsetInMinutes * 60000))
      setTransactions(trans.data)
      } catch(error) {
        console.log(error)
      }
    }
    dispatch(setLoading(false))
  };

  const handleDelete = async (noTransaksi: string) => {
    dispatch(setLoading(true))
      try{
        await agent.Transactions.delete(noTransaksi)
        toast.success("Transaksi berhasil dihapus")
        navigate('/home')
      } catch(error) {
        console.log(error)
      }
      dispatch(setLoading(false))

  };

  useEffect(() => {
    const fetchData = async () => {
    try {
        const trans = await agent.Transactions.getLast()
        if (trans !== null) setLastTrans(trans.data.noTransaksi)
    } catch (error){
        console.log(error)
    }
    };
    fetchData();
    },[])

    return(
        <Container>
        <Row className='mt-3'>
            <Col>
                <label>Start Date:</label>
                <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
            </Col>
            <Col>
                <label>End Date:</label>
                <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
            </Col>
        </Row>
        <Button variant="primary" onClick={handleGetTransactions} className="mt-3">
            Cari Transaksi
        </Button>

        <ListGroup>
                <ListGroup.Item key="header" className="font-weight-bold d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={2}>
                        <span className="fw-bold">noTransaksi</span>
                    </Col>
                    <Col xs={2}>
                        <span className="fw-bold">Kategori</span>
                    </Col>
                    <Col xs={2}>
                        <span className="fw-bold">Debit/Kredit</span>
                    </Col>
                    <Col xs={2}>
                        <span className="fw-bold">Jumlah</span>
                    </Col>
                    <Col xs={4}>
                        <span className="fw-bold">Nama Akun</span>
                    </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            {transactions.map((p) => (
                <ListGroup.Item key={p.id} className="d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={2}>
                        <span>{p.noTransaksi}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{p.category}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{p.debitKredit}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{(p.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{p.NamaPerkiraan.name}</span>
                    </Col>
                    <Col xs={2}>
                      {(p.noTransaksi === lastTrans) &&
                      <Button variant="danger" onClick={() => handleDelete(p.noTransaksi)}>
                      Hapus
                      </Button>
                      }
                    </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            ))}
        </ListGroup>
        </Container>
    )
}