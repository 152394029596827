export interface User {
    token: string;
    userId: number;
    
}

export class LoginBody {
    email:string = ""
    password:string = ""

    constructor(email: string, password: string) {
        this.email = email
        this.password = password
    }
}

export class RegisterBody {
    name:string = ""
    email:string = ""
    password:string = ""
    confirmPassword:string = ""
    role:string = ""

    constructor(name: string, email: string, password: string, confirmPassword: string, role: string) {
        this.name = name
        this.email = email
        this.password = password
        this.confirmPassword = confirmPassword
        this.role = role
    }
}