import { Klien } from "./klien";
import { Namper } from "./namper";

export interface Transaction {
    id: number;
    keterangan: string;
    value: number;
    namPerId: number;
    debitKredit: string;
    isLabaRugi: boolean;
    category: string;
    noTransaksi: string;
    saldoAkhirNamper: Date;
    Klien: Klien;
    NamaPerkiraan: Namper;
    createdAt: string;
}

export class TransactionBody {
    category:string = ""
    noTransaksi: string = ""
    keterangan: string = ""
    value: number = 0;
    debitKredit: string = "";
    isLabaRugi: boolean = false;
    klienId: number = 0;
    namPerId: number = 0
    createdAt: string = "";
    cashflowId: number | null = null

    constructor(category: string, noTransaksi: string, keterangan: string, value: number, debitKredit: string,
         isLabaRugi: boolean, klienId: number, namPerId: number, createdAt: string, cashflowId: number|null) {
        this.category = category;
        this.noTransaksi = noTransaksi;
        this.keterangan = keterangan;
        this.value = value;
        this.debitKredit = debitKredit;
        this.isLabaRugi = isLabaRugi;
        this.klienId = klienId;
        this.namPerId = namPerId;
        this.createdAt = createdAt
        this.cashflowId = cashflowId
    }
}