import { FormEvent, useState } from "react";
import { toast } from 'react-toastify';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NamperBody } from "../app/models/namper";
import agent from "../app/api/agent";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../app/stores/loadingSlice";
import { useDispatch } from "react-redux";

export default function CreateNamperPage(){
    const [chosen, setChosen] = useState('')
    const [chosen2, setChosen2] = useState('')
    const [nama, setNama] = useState('')
    const [validationError, setValidationError] = useState<string | null>(null);
    const kategories = ["ekuitas","kas","beban","hutang","persediaan","pendapatan","piutang","aset"]
    const specialKateg = ["beban","pendapatan","aset"]
    const [subKateg, setSubKateg] = useState<string[]>([]);
    const bebanSub = ["operasional", "nonoperasional", "pajak"]
    // const hutangSub = ["usaha", "lainnya"]
    const pendapatanSub = ["operasional", "nonoperasional"]
    // const piutangSub = ["usaha", "lainnya"]
    const asetSub = ["lancar", "tidak lancar"]
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setValidationError(chosen.trim() === '' ? 'Input cannot be empty' : null);
        if(!validationError && chosen !== ''){
            if(specialKateg.includes(chosen) && chosen2 ===''){
                toast.error("perbaiki data dulu")
            } else {
              dispatch(setLoading(true))
                try {
                    let chosenBody = ''
                    if(chosen2 === ''){
                        if(chosen === "hutang" || chosen === "piutang"){
                          chosenBody = chosen + " lainnya"
                        } else {
                          chosenBody = chosen
                        }
                    } else {
                        chosenBody = chosen + " " + chosen2
                    }
                    console.log('making namper')
                  await agent.Nampers.create(new NamperBody(nama, chosenBody))
                  toast.success("nama akun berhasil dibuat")
                  navigate('/home')
                }catch(error){
                  console.log(error);
              }
              dispatch(setLoading(false))
            }
        } else {
            toast.error("perbaiki data dulu")
        }
        
      };
    
      const handleChosenChange = (option: string) => {
        setChosen(option);
        setChosen2('')
        switch(option){
            case "beban":
                setSubKateg(bebanSub)
                break;
            // case "hutang":
            //     setSubKateg(hutangSub)
            //     break;
            case "pendapatan":
                setSubKateg(pendapatanSub)
                break;
            // case "piutang":
            //     setSubKateg(piutangSub)
            //     break;
            case "aset":
                setSubKateg(asetSub)
                break;
            default:
                setSubKateg([])
        }
      };

      const handleChosen2Change = (option: string) => {
        setChosen2(option);
      };

      const handleNameChange = (input: string) => {
        setNama(input);
        setValidationError(input.trim() === '' ? 'Input cannot be empty' : null);
      };

      return (
        <Container className="mt-5">
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formSelect">
                <Form.Label>Pilih kategori:</Form.Label>
                <Form.Select
                  value={chosen}
                  onChange={(e) => handleChosenChange(e.target.value)}
                >
                  <option value="" disabled>pilih kategori</option>
                  {kategories.map((kategori, index) => (
                        <option key={index} value={kategori.toLowerCase()}>{kategori}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            {(specialKateg.includes(chosen)) &&
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formSelect2">
                <Form.Label>Pilih sub kategori:</Form.Label>
                <Form.Select
                  value={chosen2}
                  onChange={(e) => handleChosen2Change(e.target.value)}
                >
                  <option value="" disabled>pilih kategori</option>
                  {subKateg.map((kategori, index) => (
                        <option key={index} value={kategori.toLowerCase()}>{kategori}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            }
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formText">
                <Form.Label>Masukkan nama:</Form.Label>
                <Form.Control
                  type="text"
                  value={nama}
                  onChange={(e) => handleNameChange(e.target.value)}
                  isInvalid={!!validationError}
                />
                <Form.Control.Feedback type="invalid">
                  {validationError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Container>
      );
}