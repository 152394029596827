import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RootState } from "../stores/store";

export default function RequireAuth() {
    const user = useSelector((state: RootState) => state.user.value); 
    const location = useLocation(); // get location where we're currently at

    if(user == null) {
        return <Navigate to='/' state={{from: location}} /> // state agar tahu dia dateng dari mana
    }

    return <Outlet />
}