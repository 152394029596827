import { Button, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function NavBar() {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <Navbar bg="light" expand="lg">
          <Navbar.Brand className="ms-5">Akuntian</Navbar.Brand>
          {location.pathname !== '/home' &&
            <Button onClick={() => navigate('/home')}variant="outline-primary">Home</Button>
            }
        </Navbar>
      );
}