import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { Klien } from "../../app/models/klien";
import { AddedProduct, EditProductBody, Product } from "../../app/models/product";
import agent from "../../app/api/agent";
import AddProductModal from "../product/AddProductModal";
import RadioButton from "../../app/common/RadioButton";
import { Namper } from "../../app/models/namper";
import { TransactionBody } from "../../app/models/transaction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/stores/store";
import { useNavigate } from "react-router-dom";
import { FakturBody } from "../../app/models/faktur";
import { toast } from "react-toastify";
import { setLoading } from "../../app/stores/loadingSlice";

export default function FakturPembelian(){
    const [showModal, setShowModal] = useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<Klien[]>([])
    const [selectedSup, setSelectedSup] = useState<number>(0)
    const [ket, setKet] = useState<string>('')
    const [metode, setMetode] = useState('tunai')
    const [products, setProducts] = useState<Product[]>([])
    const [passedProducts, setPassedProducts] = useState<Product[]>([])
    const [addedProducts, setAddedProducts] = useState<AddedProduct[]>([])
    const [sumbers, setSumbers] = useState<Namper[]>([])
    const [sumberId, setSumberId] = useState<number>(0) // isinya namper kas, hutang, dll yg terpilih
    const [sumber, setSumber] = useState<Namper>() // kaya atasnya tapi dlm bentuk namper
    const [totPrice, setTotPrice] = useState<number>(0)
    const [tambahan, setTambahan] = useState<string>("")
    const [tambahanKet, setTambahanKet] = useState<string>("")
    const [errors,setErrors] = useState<string[]>([])
    const namperAbs = useSelector((state: RootState) => state.namperAbs.value); 
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
        try {
            const produks = await agent.Products.list()
            setProducts(produks.data)
            setPassedProducts(produks.data)

            const supplierzs = await agent.Kliens.list('supplier')
            setSuppliers(supplierzs.data)

            const furom = await agent.Nampers.list('kas')
            setSumbers(furom.data)

        } catch (error){
            console.log(error)
        }
        };
        dispatch(setLoading(true))
        fetchData();
        dispatch(setLoading(false))
        setAddedProducts([])
        setErrors([])
        setTotPrice(0)
    }, [])

    const handleRadioChange = async (value: string) => {
        setMetode(value)
        if(value === 'hutang'){
            setSumberId(namperAbs.hutangUsaha)
        } else {
            setSumberId(0)
        }
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setErrors([])
        let tempErrors: string[] = []
        dispatch(setLoading(true))
        try{
            if (!/^-?\d*\.?\d*$/.test(tambahan)) {
                tempErrors.push("input nominal angka yang valid");
            }
            if(sumberId === 0 || addedProducts.length === 0 || selectedSup === 0){
                tempErrors.push("tolong lengkapi data")
            }
            if(sumber !== undefined && (metode === 'tunai' && (totPrice > sumber!.saldo))){
                tempErrors.push(`total biaya ${totPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} lebih besar dari saldo kas ${sumber!.saldo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`)
            } 
            if(tempErrors.length === 0) {
                let kategori
                let tempSumberId
                let paidValue
                const numTambahan = Number(tambahan)
                const tototPrice = totPrice+numTambahan
                if(metode === 'tunai'){
                    kategori = "Pembelian Tunai"
                    tempSumberId = sumberId
                    paidValue = tototPrice
                } else {
                    kategori = "Faktur Pembelian"
                    tempSumberId = namperAbs.hutangUsaha
                    paidValue = 0
                }
                const transaction = await agent.Transactions.create(new TransactionBody(kategori,"",ket,totPrice,"debit",false,selectedSup,namperAbs.persediaan,Date(),null))
                if(numTambahan<0){
                    await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,-numTambahan,"kredit",true,selectedSup,namperAbs.diskonPembelian,transaction.data.createdAt,null))
                }
                if(numTambahan>0){
                    await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,numTambahan,"debit",true,selectedSup,namperAbs.tambahanPembelian,transaction.data.createdAt,null))
                }
                const kreditTrans = await agent.Transactions.create(new TransactionBody(kategori,transaction.data.noTransaksi,ket,tototPrice,"kredit",false,selectedSup,tempSumberId,transaction.data.createdAt,transaction.data.id))
                for (const prod of addedProducts){
                    await agent.Products.edit(new EditProductBody(prod.quantity,prod.price),prod.id)
                }
                await agent.Fakturs.create(new FakturBody("Pembelian",transaction.data.id,addedProducts.map(product => product.id),addedProducts.map(product => product.quantity),addedProducts.map(product => product.price),Number(tambahan),tambahanKet,tototPrice,paidValue,selectedSup,kreditTrans.data.namPerId))
                toast.success("transaksi berhasil dibuat")
                navigate('/home')
            } else {
                setErrors(tempErrors)
            }
        } catch(error){
            // to do: set error
        }
        dispatch(setLoading(false))
    }

    const handleSupplierChange = (option: number) => {
        setSelectedSup(option)
    }

    const handleFromChange = (option: number) => {
        setSumberId(option)
        setSumber(sumbers.filter(item => item.id === option)[0])
    }

    const handleAddedProduct = (produk: AddedProduct) => {
        setShowModal(false);
        setAddedProducts(prevArr => [...prevArr, produk])
        setTotPrice(value => value+(produk.totPrice))
        setPassedProducts(prevArr => prevArr.filter(item => item.id !== produk.id) )              
    }

    const removeAddedProduct = (id:number) => {
        const delProduk = addedProducts.find(p => p.id === id)
        setTotPrice(value => value-delProduk!.totPrice)
        setAddedProducts(prevArr => prevArr.filter(item => item.id !== id))
        const toAdd = products.find(p => p.id === id)
        setPassedProducts(prevArr => [...prevArr,toAdd!])
    }   

    const handleModalClose = () => {
        setShowModal(false);
      };
    
    return (
        <Container className="mt-5">
            <h1 className="mb-3">Faktur Pembelian:</h1>
            {errors.map((er, index) => (
                <div key={index} style={{color:'red'}}>{er}</div>
            ))}
            <Form onSubmit={handleSubmit}>
            <Form.Group as={Col} controlId="formSupplier" className="mb-3">
                <Form.Label>Pilih Supplier:</Form.Label>
                <Form.Select
                  value={selectedSup}
                  onChange={(e) => handleSupplierChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {suppliers.map((s, index) => (
                        <option key={index} value={s.id}>{s.nama}</option>
                    ))}
                </Form.Select>
            </Form.Group>
                <Row className="mb-3">
                    <RadioButton
                    label="tunai"
                    value="tunai"
                    checked={metode === 'tunai'}
                    onSelectionChange={handleRadioChange}
                    />
                    <RadioButton
                    label="hutang"
                    value="hutang"
                    checked={metode === 'hutang'}
                    onSelectionChange={handleRadioChange}
                    />
                </Row>
            <Row className="mb-3" hidden={metode==="hutang"}>
              <Form.Group as={Col} controlId="formJenis">
                <Form.Label>Pilih Sumber:</Form.Label>
                <Form.Select
                  value={sumberId}
                  onChange={(e) => handleFromChange(parseInt(e.target.value))}
                >
                  <option value="0" disabled>-</option>
                  {sumbers.map((f, index) => (
                        <option key={index} value={f.id}>{f.name}</option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Tulis Keterangan:</Form.Label>
                <Form.Control
                    placeholder="keterangan"
                    value={ket}
                    onChange={(e) => setKet(e.target.value)}
                />
            </Form.Group>
            <Button className="mb-3" onClick={() => setShowModal(true)}>Tambahkan Produk</Button>
            <ListGroup>
                <ListGroup.Item key="header" className="font-weight-bold d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col>
                        <span className="fw-bold">Nama</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Kuantitas</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Harga per Produk</span>
                    </Col>
                    <Col>
                        <span className="fw-bold">Total per Produk</span>
                    </Col>
                </Row>
                </Container>
            </ListGroup.Item>
            {addedProducts.map((p) => (
                <ListGroup.Item key={p.id} className="d-flex justify-content-between align-items-center">
                <Container>
                <Row>
                    <Col xs={3}>
                        <span>{p.name}</span>
                    </Col>
                    <Col xs={3}>
                        <span>{p.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={3}>
                        <span>{p.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={2}>
                        <span>{p.totPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </Col>
                    <Col xs={1}>
                    <Button variant="outline-danger" onClick={() => removeAddedProduct(p.id)}>
                    Delete
                    </Button>
                </Col>
                </Row>
                </Container>
                </ListGroup.Item>
            ))}
            </ListGroup>
            <Form.Group controlId="numberInput" className="mt-3">
                <Form.Label>Input nominal tambahan: (diskon, additional charge, dsb yang mengubah nilai akhir hutang)</Form.Label>
                <Form.Control
                type="text"
                placeholder="input angka >0 untuk menambahkan, <0 untuk mengurangi"
                value={tambahan}
                onChange={(e) => setTambahan(e.target.value)}
                />
            </Form.Group>
            <Form.Group controlId="kettamInput" className="mt-3">
                <Form.Label>Input keterangan tambahan:</Form.Label>
                <Form.Control
                type="text"
                placeholder="keterangan tambahan"
                value={tambahanKet}
                onChange={(e) => setTambahanKet(e.target.value)
                }
                />
            </Form.Group>
            <Row className="mt-2">
                <Col xs={9}>
                <Button variant="primary" type="submit">Submit</Button>
                </Col>
                <Col xs={3}>
                    <span>total: Rp {(totPrice+Number(tambahan)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                </Col>
            </Row>
            </Form>
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                <Modal.Title>Modal Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <AddProductModal products={passedProducts} onSelectionChange={handleAddedProduct} tipe="beli"/>
                </Modal.Body>
            </Modal>
        </Container>
    )
}