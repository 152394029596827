import React from 'react';
import { Spinner } from 'react-bootstrap';
import './styles.css'

export default function LoadingComponent(){
    return (
        <div className="loading-spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      );
}
