import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import namperAbsReducer from './namperSlice';
import errorServerReducer from './errorServerSlice';
import loadingReducer from './loadingSlice'
import storage from "redux-persist/lib/storage";
import {persistReducer} from 'redux-persist'
import {combineReducers} from '@reduxjs/toolkit'

const persistConfig = {
  key: "root",
  version: 1,
  storage
}

const reducer = combineReducers({
  user: userReducer,
  namperAbs: namperAbsReducer,
  errorServer: errorServerReducer,
  loading: loadingReducer
})

const persistedReducer = persistReducer(persistConfig,reducer)

export const store = configureStore({
  reducer: persistedReducer
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch