import { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import agent from "../app/api/agent";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setLoading } from "../app/stores/loadingSlice";
import { KlienBody } from "../app/models/klien";

export default function CreateKlienPage(){
    const [name, setName] = useState('');
    const [alamat, setAlamat] = useState('');
    const [type, setType] = useState('-');
    const [errors,setErrors] = useState<string[]>([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        dispatch(setLoading(true))
        try {
            await agent.Kliens.create(new KlienBody(name, alamat), type) // pke productbody krn isinya cuma name, sama kaya product
            toast.success("klien berhasil dibuat")
            navigate('/home')
        }catch(error){
            if(Array.isArray(error)){
                setErrors(error)
            }
            console.log("unauthorized from react")
            console.log(error);
        }
        dispatch(setLoading(false))
    };

    const handleChosenChange = (option: string) => {
        setType(option);
      };
  
    return (
      <Container>
        <h1 className="mt-3">Create Klien</h1>
        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSelect" className="mt-3">
                <Form.Label>Pilih kategori:</Form.Label>
                <Form.Select
                  value={type}
                  onChange={(e) => handleChosenChange(e.target.value)}
                >
                  <option value="-" disabled>pilih kategori</option>
                    <option value="supplier">supplier</option>
                    <option value="customer">customer</option>
                </Form.Select>
              </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Nama Klien:</Form.Label>
            <Form.Control
              placeholder="Nama Klien"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Alamat Klien:</Form.Label>
            <Form.Control
              placeholder="Alamat Klien"
              value={alamat}
              onChange={(e) => setAlamat(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Create
          </Button>
        </Form>
        {errors.map((er, index) => (
            <div key={index} style={{color:'red'}}>{er}</div>
        ))}
      </Container>
    );
  };