import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import LoginPage from "../../features/user/LoginPage";
import HomePage from "../../features/HomePage";
import App from "../layout/App";
import NotFound from "../../features/errors/NotFound";
import TransactionListPage from "../../features/TransactionListPage";
import ProductListPage from "../../features/product/ProductListPage";
import CreateProductPage from "../../features/product/CreateProductPage";
import CreateNamperPage from "../../features/CreateNamperPage";
import CreateKlienPage from "../../features/CreateKlienPage";
import PencatatanBeban from "../../features/transaction/PencatatanBeban";
import FakturPembelian from "../../features/transaction/FakturPembelian";
import FakturPenjualan from "../../features/transaction/FakturPenjualan";
import TanamModal from "../../features/TanamModal";
import FakturListPage from "../../features/FakturListPage";
import Pembayaran from "../../features/transaction/Pembayaran";
import LaporanPage from "../../features/LaporanPage";
import ServerError from "../../features/errors/ServerError";
import RequireAuth from "./RequireAuth";
import RegisterPage from "../../features/user/RegisterPage";
import PencatatanDapat from "../../features/transaction/PencatatanDapat";
import TransferDana from "../../features/TransferDana";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {path: '', element: <LoginPage />},
            {element: <RequireAuth />, children:[
                {path: 'register', element: <RegisterPage />},
                {path: 'home', element: <HomePage />},
                {path: 'products', element: <ProductListPage />},
                {path: 'transactions', element: <TransactionListPage />},
                {path: 'fakturs', element: <FakturListPage />},
                {path: 'createProduct', element: <CreateProductPage />},
                {path: 'createKlien', element: <CreateKlienPage />},
                {path: 'createNamper', element: <CreateNamperPage />},
                {path: 'tanamModal', element: <TanamModal />},
                {path: 'transferDana', element: <TransferDana />},
                {path: 'catatBeban', element: <PencatatanBeban />},
                {path: 'pembayaranBeban', element: <Pembayaran category="Beban"/>},
                {path: 'catatDapat', element: <PencatatanDapat />},
                {path: 'penerimaanDapat', element: <Pembayaran category="Pendapatan"/>},
                {path: 'fakturPembelian', element: <FakturPembelian />},
                {path: 'pembayaranPembelian', element: <Pembayaran category="Pembelian"/>},
                {path: 'fakturPenjualan', element: <FakturPenjualan />},
                {path: 'penerimaanPenjualan', element: <Pembayaran category="Penjualan" />},
                {path: 'jurnal', element: <LaporanPage category="Jurnal Umum"/>},
                {path: 'neraca', element: <LaporanPage category="Neraca"/>},
                {path: 'labarugi', element: <LaporanPage category="Laporan Laba Rugi"/>},
                {path: 'cashflow', element: <LaporanPage category="Arus Kas"/>},
            ]},
            {path: 'server-error', element: <ServerError/>},
            {path: 'not-found', element: <NotFound/>},
            {path: '*', element: <Navigate replace to='/not-found'/>}
        ]
    }
]

export const router = createBrowserRouter(routes);