import React, { useState, FormEvent } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import agent from '../../app/api/agent';
import { RegisterBody } from '../../app/models/user';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../app/stores/loadingSlice';

export default function RegisterPage(){
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [role, setRole] = useState('');
  const [errors,setErrors] = useState<string[]>([])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setErrors([])
    dispatch(setLoading(true))
    try {
      await agent.Users.register(new RegisterBody(name,email,password,password2,role))
      toast.success("User berhasil dibuat")
      navigate('/home')
    }catch(error){
      console.log(error);
      if(Array.isArray(error)){
        setErrors(error)
      }      
    }
    dispatch(setLoading(false))
  };

  return (
    <Container>
      <h2>Daftarkan Pengguna</h2>
      {errors.map((er, index) => (
            <div key={index} style={{color:'red'}}>{er}</div>
        ))}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName" className="mt-3">
          <Form.Label>nama</Form.Label>
          <Form.Control
            placeholder=""
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="mt-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="mt-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formConfirm" className="mt-3">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formRole" className="mt-3">
                <Form.Label>Pilih Role:</Form.Label>
                <Form.Select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="" disabled>-</option>
                  <option value="sales">sales</option>
                    <option value="finance">finance</option>
                </Form.Select>
              </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Login
        </Button>
      </Form>
    </Container>
  );
};