import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../app/stores/store';
import { Button, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../app/stores/userSlice';
import { useEffect } from 'react';
import { setLoading } from '../app/stores/loadingSlice';

export default function HomePage(){
    const navigate = useNavigate()
    const namperAbs = useSelector((state: RootState) => state.namperAbs.value); 
    const dispatch = useDispatch();

    const redPageStyle: React.CSSProperties = {
        backgroundColor: 'red',
        height: '100vh', // Set the height to fill the entire viewport
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white', // Set text color to white for better visibility
      };

    const logout = () => {
        dispatch(setUser(null))
        navigate('/')
    }

    useEffect(() => {
        dispatch(setLoading(false))
    },[])
      
    return(
        <Container className="mt-3">
            <h1 className="mb-3">Home Page</h1>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/register')}>
                buat user
                </Button>
                <Button variant="primary" onClick={() => navigate('/createNamper')}>
                buat nama akun  
                </Button>
                <Button variant="primary" onClick={() => navigate('/createKlien')}>
                buat klien  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/products')}>
                daftar produk  
                </Button>
                <Button variant="primary" onClick={() => navigate('/transactions')}>
                daftar transaksi  
                </Button>
                <Button variant="primary" onClick={() => navigate('/fakturs')}>
                    daftar faktur
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/tanamModal')}>
                tanam modal  
                </Button>
                <Button variant="primary" onClick={() => navigate('/transferDana')}>
                transfer dana  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/fakturPembelian')}>
                faktur pembelian  
                </Button>
                <Button variant="primary" onClick={() => navigate('/pembayaranPembelian')}>
                pembayaran pembelian  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/catatBeban')}>
                pencatatan beban  
                </Button>
                <Button variant="primary" onClick={() => navigate('/pembayaranBeban')}>
                pembayaran beban  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/catatDapat')}>
                pencatatan pendapatan  
                </Button>
                <Button variant="primary" onClick={() => navigate('/penerimaanDapat')}>
                penerimaan pendapatan  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/fakturPenjualan')}>
                faktur penjualan  
                </Button>
                <Button variant="primary" onClick={() => navigate('/penerimaanPenjualan')}>
                penerimaan penjualan  
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={() => navigate('/jurnal')}>
                    jurnal umum
                </Button>
                <Button variant="primary" onClick={() => navigate('/neraca')}>
                    neraca
                </Button>
                <Button variant="primary" onClick={() => navigate('/labarugi')}>
                    laba rugi
                </Button>
                <Button variant="primary" onClick={() => navigate('/cashflow')}>
                    arus kas
                </Button>
            </Row>
            <Row className="mb-3">
                <Button variant="primary" onClick={logout}>
                    Logout
                </Button>
            </Row>
        </Container>
    )
}