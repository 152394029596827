import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import NavBar from './Navbar';
import LoadingComponent from './LoadingComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/store';

function App() {
  const location = useLocation()
  const isLoading = useSelector((state: RootState) => state.loading.value); 

  return (
    <>
    <ToastContainer position="bottom-right" hideProgressBar theme='colored' />
    {isLoading && <LoadingComponent />}
    {location.pathname !== '/' &&
      <NavBar/>
    }
    <Outlet />
    </>
  );
}

export default App;
